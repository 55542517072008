<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i> Lieferanten</h4>
                            <p>Liste aller Lieferanten</p>
                        </div>

                        <div class="card-tools">

                            <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                                <ul class="navbar-nav">  
                                    <li class="nav-item mr-1">
                                        <div class="input-group input-group-sm" style="margin-top: 0;">
                                            <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="loadDistributors" :value="search" @input="$store.commit('distributors/changeSearch', $event.target.value)" />
                                            <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadDistributors">
                                                <i class="fas fa-fw fa-search"></i>
                                            </button>
                                            <button v-if="this.search != ''" type="button" class="ml-1 btn btn-danger btn-sm" @click="resetSearch">
                                                <i class="fas fa-fw fa-times"></i>
                                            </button>
                                        </div>
                                    </li>

                                    <li class="nav-item">
                                        <button type="button" class="mr-1 btn btn-sm btn-default" @click="loadDistributors">
                                            <i class="fas fa-sync"></i>
                                        </button>
                                    </li>

                                    <li class="nav-item">
                                        <button type="button" class="btn btn-sm btn-primary" @click="createModal" v-if="$auth.check('distributors.create')">
                                            <i class="fas fa-plus-circle"></i> Neuer Lieferant
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                        <table class="table table-hover table-sm">
                            <thead>
                                <th><input type="checkbox" v-model="selectAll" /></th>
                                <th>
                                    <a href="#" @click.prevent="changeSort('distributornumber')">Lieferantennummer</a>
                                    <span v-if="this.params.sort_field == 'distributornumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                    <span v-if="this.params.sort_field == 'distributornumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                                </th>
                                <th>
                                    <a href="#" @click.prevent="changeSort('name')">Name</a>
                                    <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                    <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                </th>
                                <th style="width: 170px;">Aktionen</th>                 
                            </thead>
                            <tbody>
                                <tr v-for="(distributor) in distributors.data" :key="distributor.id">
                                    <td><input type="checkbox" :value="distributor.id" v-model="selectedDistributors"/></td>
                                    <td>{{ distributor.distributornumber }}</td>
                                    <td>{{ distributor.name }}</td>
                                    <td style="display:flex">
        
                                        <!-- <router-link class="mr-1 btn btn-basic btn-xs" style="border-color: black;" :to="{name: 'distributors.penner', params: {id: distributor.id , d_name: distributor.name}}"><i class="fas fa-fw fa-bed"></i></router-link> -->
                                        <router-link class="mr-1 btn btn-info btn-xs" :to="{name: 'supplier-orders.create-single', params: {id: distributor.id}}" v-if="$auth.check('distributors.edit')"><i class="fas fa-fw fa-truck"></i></router-link>
                                        <b-button class="mr-1" size="xs" @click="downloadDistributorArticles(distributor)" variant="secondary" v-if="$auth.check('distributors.edit')"><i class="fas fa-fw fa-download"></i></b-button>
                                        <router-link class="mr-1 btn btn-success btn-xs" :to="{name: 'distributors.show', params: {id: distributor.id}}" v-if="$auth.check('distributors.show') && !$auth.check('distributors.edit')"><i class="fas fa-fw fa-eye"></i></router-link>
                                        <router-link class="mr-1 btn btn-warning btn-xs" :to="{name: 'distributors.details', params: {id: distributor.id}}" v-if="$auth.check('distributors.edit')"><i class="fas fa-fw fa-edit"></i></router-link>
                                        <b-button size="xs" @click="deleteDistributor(distributor.id)" variant="danger" v-if="$auth.check('distributors.destroy')"><i class="fas fa-fw fa-trash"></i></b-button>
                                        <b-button class="ml-1" size="xs" @click="showCreditModal(distributor)" variant="success" v-if="$auth.check('distributors.edit') && distributor.useCredit == true"><i class="fas fa-fw fa-dollar-sign"></i></b-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>

                        <div class="card-footer">
                            <template v-if="distributors.data.length > 0">
                                <div class="row align-items-center">
                                    <div class="col-md-9">
                                        <pagination class="float-left" :data="distributors" @pagination-change-page="loadDistributors" :limit="3"></pagination>
                                    </div>
                                    <div class="col-md-2">
                                        <span class="float-right">Anzeige Eintrag {{ distributors.meta.from }} - {{ distributors.meta.to }} von {{ distributors.meta.total }}</span>
                                    </div>
                                    <div class="col-md-1">
                                        <select class="float-right form-control form-control-sm" v-model="params.per_page">
                                            <option value="25">25 Artikel</option>
                                            <option value="50">50 Artikel</option>
                                            <option value="75">75 Artikel</option>
                                            <option value="100">100 Artikel</option>
                                            <option value="125">125 Artikel</option>
                                            <option value="150">150 Artikel</option>
                                        </select>
                                    </div>
                                </div>                        
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="distributorModal" title="Neuen Lieferanten anlegen" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk">
                <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">
                    <div class="row">
                        <div class="col-md-12">

                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="name" class="control-label">Name*</label>
                                        <input v-model="form.name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('name')}">
                                        <has-error :form="form" field="name"></has-error>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="owner" class="control-label">Inhaber</label>
                                        <input v-model="form.owner" name="owner" id="owner" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('owner')}">
                                        <has-error :form="form" field="owner"></has-error>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="ustid" class="control-label">USt-Id.</label>
                                        <input v-model="form.ustid" name="ustid" id="ustid" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('ustid')}">
                                        <has-error :form="form" field="ustid"></has-error>
                                    </div>
                                </div>
                            </div>
                            


                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch1" value="1" v-model="send_email">
                                            <label class="custom-control-label" for="customSwitch1">Bestellung per Mail</label>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="email" class="control-label">E-Mail*</label>
                                        <input v-model="form.email" type="email" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('email')}" :disabled="form.send_email == 0">
                                        <has-error :form="form" field="email"></has-error>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="send_phone">
                                            <label class="custom-control-label" for="customSwitch2">Bestellung per Telefon</label>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="email" class="control-label">Telefon*</label>
                                        <input v-model="form.phone" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('phone')}" :disabled="form.send_phone == 0"> 
                                        <has-error :form="form" field="phone"></has-error>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch3" v-model="send_web">
                                            <label class="custom-control-label" for="customSwitch3">Bestellung per Webshop</label>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="email" class="control-label">URL*</label>
                                        <input v-model="form.web" type="email" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('web')}" :disabled="form.send_web == 0">
                                        <has-error :form="form" field="web"></has-error>
                                    </div>
                                </div>


                                <div class="col-md-3">
                                    <div class="form-group">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch4" v-model="use_credit">
                                            <label class="custom-control-label" for="customSwitch4">Teilnahme am Gutschriftverfahren</label>
                                        </div>
                                    </div>
                                    <div class="form-group" >
                                        <label for="email" class="control-label">E-Mail*</label>
                                        <input v-model="form.credit_email" type="email" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('credit_email')}" :disabled="form.useCredit == 0">
                                        <has-error :form="form" field="credit_email"></has-error>
                                    </div>
                                </div>

                            </div>

                            

                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label for="street" class="control-label">Straße*</label>
                                                            <input v-model="form.street" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('street')}" >
                                                            <has-error :form="form" field="street"></has-error>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label for="house_number" class="control-label">Nr.*</label>
                                                            <input v-model="form.house_number" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('house_number')}">
                                                            <has-error :form="form" field="house_number"></has-error>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <label for="zipcode" class="control-label">PLZ*</label>
                                                        <input v-model="form.zipcode" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('zipcode')}">
                                                        <has-error :form="form" field="zipcode"></has-error>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <label for="city" class="control-label">Ort*</label>
                                                        <input v-model="form.city" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('city')}">
                                                        <has-error :form="form" field="city"></has-error>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" id="customSwitch8" v-model="use_warenausgabe">
                                                    <label class="custom-control-label" for="customSwitch8">Abweichende Warenausgabe</label>
                                                </div>
                                            </div>

                                            <div class="form-group" v-if="use_warenausgabe == true">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label for="street_pickup" class="control-label">Straße</label>
                                                            <input v-model="form.street_pickup" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('street_pickup')}" >
                                                            <has-error :form="form" field="street_pickup"></has-error>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label for="house_number_pickup" class="control-label">Nr.</label>
                                                            <input v-model="form.house_number_pickup" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('house_number_pickup')}">
                                                            <has-error :form="form" field="house_number_pickup"></has-error>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <label for="zipcode_pickup" class="control-label">PLZ</label>
                                                        <input v-model="form.zipcode_pickup" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('zipcode_pickup')}">
                                                        <has-error :form="form" field="zipcode_pickup"></has-error>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <label for="city_pickup" class="control-label">Ort</label>
                                                        <input v-model="form.city_pickup" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('city_pickup')}">
                                                        <has-error :form="form" field="city_pickup"></has-error>
                                                    </div>

                                                </div>
                                            </div>

                            
                            <div class="row">
                                <div class="col-md-4">
                                    <label for="iban" class="control-label">IBAN</label>
                                    <input v-model="form.iban" type="string" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('iban')}">
                                    <has-error :form="form" field="iban"></has-error>
                                </div>

                                <div class="col-md-4">
                                    <label for="bic" class="control-label">BIC</label>
                                    <input v-model="form.bic" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('bic')}">
                                    <has-error :form="form" field="bic"></has-error>
                                </div>

                                <div class="col-md-4">
                                    <label for="bank" class="control-label">Bank</label>
                                    <input v-model="form.bank" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('bank')}">
                                    <has-error :form="form" field="bank"></has-error>
                                </div>
                            </div>


                            <div class="form-group">
                                <label for="description" class="control-label">Allgemeine Infos (Intern)</label>
                                <ckeditor :editor="editor" :config="editorConfig" v-model="form.description" :class="{'is-invalid': form.errors.has('description')}"></ckeditor>
                                <has-error :form="form" field="description"></has-error>
                            </div>

                        </div>
                    </div>
                </form>
            </b-modal>

            <b-modal id="creditModal" title="Neue Gutschrift anlegen" ok-only ok-variant="danger" ok-title="Speichern" size="sm" @ok="handleCreditOk">
                <div class="row">
                    <div class="col-md-12">
                        <label for="start_date" class="control-label">Start-Datum:</label>
                        <input v-model="creditForm.start_date" type="date" class="form-control form-control-sm" :class="{'is-invalid': creditForm.errors.has('start_date')}">
                        <has-error :form="creditForm" field="start_date"></has-error>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-12">
                        <label for="stop_date" class="control-label">Stop-Datum:</label>
                        <input v-model="creditForm.stop_date" type="date" class="form-control form-control-sm" :class="{'is-invalid': creditForm.errors.has('stop_date')}">
                        <has-error :form="creditForm" field="stop_date"></has-error>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-12">
                        <label for="send_mail" class="control-label">E-Mail senden:</label>
                        <select v-model="creditForm.send_mail" class="form-control form-control-sm" :class="{'is-invalid': creditForm.errors.has('send_mail')}">
                            <option :value=1>Ja</option>
                            <option :value=0>Nein</option>
                        </select>
                        <has-error :form="creditForm" field="send_mail"></has-error>
                    </div>
                </div>
            </b-modal>

    </b-container>
</template>

<script>
import ClassicEditor from './../../assets/packages/ckeditor5/src/ckeditor';
import {mapState} from 'vuex';

export default {
    name: "Distributors",
    title: "Lieferanten",
    data() {
        return {
            form: new window.UploadForm({
                id: "",
                name: "",
                description: "",
                street: "",
                house_number: "",
                zipcode: "",
                city: "",
                send_email: 0,
                email: "",
                send_phone: 0,
                phone: "",
                send_web: 0,
                web: "",
                useCredit: 0,
                credit_emaiL: "",
                use_warenausgabe: false,
                street_pickup: "",
                house_number_pickup: "",
                zipcode_pickup: "",
                city_pickup: "",
                owner: "",
                ustid: "",
                bank: "",
                iban: "",
                bic: "",
            }),
            send_email: false,
            send_phone: false,
            use_credit: false,
            send_web: false,
            use_warenausgabe: false,
            distributors: {
                data: []
            },
            selectedDistributors: [],
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            creditForm: new window.Form({
                start_date: null,
                stop_date: null,
                send_mail: 0,
            }),
            creditId: null,
            
            // params: {
            //     sort_field: 'distributornumber',
            //     sort_direction: 'asc',
            //     per_page: 25,
            // },
            // page: 1,
            // search: "",
        }
    },

    watch:{
        params: {
            handler () {
                this.loadDistributors();
            },
            deep: true
        },

        use_warenausgabe: function(){
            //this.form.email = "";
            if(this.use_warenausgabe == true)
            {
                this.form.use_warenausgabe = 1;
            }
            else
            {
                this.form.use_warenausgabe = 0;
            }
        },

        send_email: function(){
            this.form.email = "";
            if(this.send_email == true)
            {
                this.form.send_email = 1;
            }
            else
            {
                this.form.send_email = 0;
            }
        },

        send_phone: function(){
            this.form.phone = "";
            if(this.send_phone == true)
            {
                this.form.send_phone = 1;
            }
            else
            {
                this.form.send_phone = 0;
            }
        },

        send_web: function(){
            this.form.web = "";
            if(this.send_web == true)
            {
                this.form.send_web = 1;
            }
            else
            {
                this.form.send_web = 0;
            }
        },

        use_credit: function(){
            this.form.credit_email = "";
            if(this.use_credit == true)
            {
                this.form.useCredit = 1;
            }
            else
            {
                this.form.useCredit = 0;
            }
        }
    },

    computed: {
        selectAll: {
            get: function () {
                return this.distributors.data ? this.selectedDistributors.length == this.distributors.data.length : false;
            },
            set: function (value) {
                var selected = [];

                if(value) {
                    this.distributors.data.forEach(function (article) {
                        selected.push(article.id);
                    });
                }

                this.selectedDistributors = selected;
            }
        }, 
        ...mapState('distributors', ['params', 'search', 'page'])
    },

    methods: {
        handleCreditOk(bvModalEvt){
            bvModalEvt.preventDefault()
            this.creditForm
                .post("/distributors/" + this.creditId + '/credit')
                .then(() => {
                    this.$bvModal.hide("creditModal");
                    this.$swal({
                        icon: "success",
                        title: "Gutschrift wurde angelegt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.creditId = null;
                    this.$bvModal.hide('creditModal');
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }, 

        showCreditModal(distributor){
            this.creditId = distributor.id
            this.$bvModal.show("creditModal")
        },

        resetSearch(){
            this.$store.commit('distributors/changeSearch', '');
            this.loadDistributors(); 
        },

        downloadDistributorArticles(distributor){
            this.axios
                .get("/distributors/" + distributor.id + '/export/articles/pdf',{
                    responseType: 'blob',
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                    );

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", distributor.name + '_Artikel.pdf');
                    document.body.appendChild(link);

                    // Dynamicall click the a link element
                    // This will download the csv file
                    link.click();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
    
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },
        
        deleteSelectedDistributors(){
            this.$swal({
                    title: "Möchtest du die selektierten Lieferanten wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, LÖSCHEN!!!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .post("/distributors/deleteMany", {data: this.selectedDistributors})
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Lieferanten erfolgreich gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.selectedDistributors = [];
                                this.loadDistributors();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },

        selectImage(event){
            this.form.logo = event.target.files[0];
            console.log(event.target.files[0]);
            console.log(this.form.logo);

        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.createDistributor();
        },

        createModal() {
            this.form.clear();
            this.form.reset();
            if(this.use_warenausgabe == true)
            {
                this.form.use_warenausgabe = 1;
            }
            else
            {
                this.form.use_warenausgabe = 0;
            }
            this.$bvModal.show("distributorModal");
        },

        deleteDistributor(id) {
            this.$swal({
                    title: "Möchtest du den Lieferanten wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Löschen`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.form
                            .delete("/distributors/" + id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Lieferant erfolgreich gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.loadDistributors();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },

        createDistributor() {
            this.$Progress.start();
            this.form
                .post("/distributors")
                .then(() => {
                    this.$bvModal.hide("distributorModal");
                    this.$swal({
                        icon: "success",
                        title: "Lieferant wurde angelegt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadDistributors();
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },


        loadDistributors(page) {
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/distributors", {
                    params:{
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.distributors = response.data;
                    this.$store.commit('distributors/setPage', page);
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }
    },

    created() {
        this.loadDistributors();
    },



}
</script>

<style>

</style>